import { Component, EventEmitter, HostListener, Output } from "@angular/core";
import { ChunkedUploader } from '../../_helpers/chunked.uploader';
import { UploaderFile } from "../../_models/uploader.file";

@Component({
  standalone: false,
  selector: 'dashboard-upload',
  templateUrl: './dashboard-upload.component.html',
  styleUrls: ['./dashboard-upload.component.scss']
})
export class DashboardUploadComponent {
  @Output() onFileUploaded: EventEmitter<any> = new EventEmitter();
  @Output() onFileProgress: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() onCancelled: EventEmitter<any> = new EventEmitter();
  @Output() onRemoved: EventEmitter<any> = new EventEmitter();
  @Output() onPaused: EventEmitter<any> = new EventEmitter();
  @Output() onResumed: EventEmitter<any> = new EventEmitter();

  public files: UploaderFile[];

  constructor(private uploader: ChunkedUploader) {
    this.files = [];
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.uploadFiles(event.dataTransfer?.files);
  }

  selectFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    this.uploadFiles(element.files);
  }

  delete(file: UploaderFile) {
    const index = this.files.findIndex(i => i == file);
    if (index > -1) {
      this.onRemoved.emit([file.index, file.guid]);
      this.files.splice(index, 1);
    }
  }

  pause(file: UploaderFile) {
    file.isPaused = !file.isPaused;
    if (!file.isPaused) {
      this.uploader.resume(file);
      this.onResumed.emit([file.index, file.guid]);
    }
    else {
      this.onPaused.emit([file.index, file.guid]);
    }
  }

  cancel(file: UploaderFile) {
    file.isCancelled = !file.isCancelled;
  }

  uploadFiles(fileList: FileList | null | undefined) {
    if (fileList) {
      let i = 0;
      Array.from(fileList).forEach(file => {
        var uploaderFile = this.uploader.open(
          file,
          i,
          (index, rangeStart, guid) => this.onFileProgress.emit([index, rangeStart, guid]),
          (index, guid) => this.onFileUploaded.emit([index, guid]),
          (index, message) => this.onError.emit([index, message]),
          (index) => this.onCancelled.emit([index]));
        i++;

        this.files.push(uploaderFile);
      });
    }
  }
}
