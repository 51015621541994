<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Report</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [style.width.%]="100">
      <mat-label>Report Name</mat-label>
      <input matInput [(ngModel)]="reportName" required>
    </mat-form-field>
  </div>
</div>
<ng-wizard [config]="wizardConfig">
    <ng-wizard-step [title]="'Layer types'">
        <table class="table table-sm table-striped table-hover">
          <thead>
            <tr>
              <th class="w-40"></th>
              <th>Layer name</th>
              <th class="text-center w-160">Group by asset</th>
              <th class="text-center w-160">Show event details</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let layerType of dataLayers">
              <td><input type="checkbox" [(ngModel)]="layerType.selected" (change)="updateLayerSelected(layerType)"></td>
              <td>{{layerType.name}}</td>
              <td class="text-center"><input type="radio" value="0" [name]="'layerType-' + layerType.guid" [(ngModel)]="layerType.mode" [disabled]="!layerType.canBeAssetView" (change)="selectLayerMode(layerType)" (click)="selectLayerMode(layerType)" (keydown)="selectLayerMode(layerType)"></td>
              <td class="text-center"><input type="radio" value="1" [name]="'layerType-' + layerType.guid" [(ngModel)]="layerType.mode" [disabled]="!layerType.canBeEventView" (change)="selectLayerMode(layerType)" (click)="selectLayerMode(layerType)" (keydown)="selectLayerMode(layerType)"></td>
            </tr>
          </tbody>
        </table>
        <div class="actions">
          <button class="btn btn-sm btn-primary btn-next" [disabled]="!isAnyLayerSelected" (click)="showNextStep($event)">Next</button>
        </div>
    </ng-wizard-step>

    <ng-wizard-step [title]="'Filters'">
      <ul class="selected-data-layers-list">
        @for (dataLayer of dataLayers; track dataLayer.guid) {
        <li *ngIf="dataLayer.selected" class="selected-data-layer">
          <h6 class="label">{{dataLayer.name}}</h6>
          <ul class="selected-filters-list">
            <li *ngFor="let filter of dataLayer.availableFilters" class="selected-item">
              <span class="label me-1" [innerHTML]="filter.title"></span>
              <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'numberlimited'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="numberlimited"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
              <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
              <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
              <app-lookup [noWidthLimit]="true" *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
              <div *ngIf="filter.type == 'pass_or_compare'" class="pass-or-compare">
                <app-lookup [name]="'PASSORCOMPARE'" mode="singlenn" [(ngModel)]="filter.dateMode" [noWidthLimit]="true"></app-lookup>
                <div>
                  <div class="dates">
                    <label class="form-label form-label-sm m-1 ms-0" [for]="filter.id + '_group'">By Pass: </label>
                    <app-lookup [name]="'GROUP'" mode="singlenn" [(ngModel)]="filter.group" [noWidthLimit]="true" [id]="filter.id + '_group'"></app-lookup>
                    <label class="form-label form-label-sm m-1" [for]="filter.id + '_date'">or by Date: </label>
                    <app-filter-input type="date" [(ngModel)]="filter.value" [id]="filter.id + '_date'"></app-filter-input>
                  </div>
                  <ng-container *ngIf="filter.dateMode == 1">
                    <div class="dates">
                      <label class="form-label form-label-sm m-1 ms-0" [for]="filter.id + '_group2'">By Pass: </label>
                      <app-lookup [name]="'GROUP'" mode="singlenn" [(ngModel)]="filter.group2" [noWidthLimit]="true" [id]="filter.id + '_group2'"></app-lookup>
                      <label class="form-label form-label-sm m-1" [for]="filter.id + '_date2'">or by Date: </label>
                      <app-filter-input type="date" [(ngModel)]="filter.value2" [id]="filter.id + '_date2'"></app-filter-input>
                    </div>
                  </ng-container>
                </div>
              </div>
            </li>
          </ul>
        </li>
        }
      </ul>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="goToSelectColumns()">Next</button>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [title]="'Columns'">
      <table class="table table-sm table-striped table-hover">
        <thead>
        <tr>
          <th class="w-40">Show/Hide</th>
          <th>Column name</th>
          <th>Source layer</th>
        </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="dropColumn($event)">
          @for (column of selectedColumns; track column.parentName + column.field) {
            <tr cdkDrag>
              <td><input type="checkbox" [(ngModel)]="column.selected"></td>
              <td [innerHTML]="column.title"></td>
              <td>{{column.parentName}}</td>
            </tr>
          }
        </tbody>
      </table>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="goToSummary()">Next</button>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [title]="'Summary / Preview'">
      <h3>{{reportName}}</h3>
      <div class="gridPreview">
        <app-grid [data]="fakeData" [config]="previewGridConfig"></app-grid>
      </div>
      <div class="actions">
        <button class="btn btn-sm btn-primary btn-prev" (click)="showPreviousStep($event)">Prev</button>
        <button class="btn btn-sm btn-primary btn-next" (click)="create()" [disabled]="createLoader">
          <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
          Create
        </button>
      </div>
    </ng-wizard-step>
</ng-wizard>
