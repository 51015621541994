<div class="d-flex flex-row align-items-center pt-2 pb-2 mb-2 border-bottom">
  <h1 class="h4 header">Create Custom Data Layer</h1>
  <div class="name-input">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" [style.width.%]="100">
      <mat-label>Custom Data Layer Name</mat-label>
      <input matInput [(ngModel)]="customDataLayerName" required>
    </mat-form-field>
  </div>
</div>
<div class="row">
  <div class="col-3">
    <h5>1. Choose custom data layer input(s)</h5>
    <div class="my-1">
      <ng-select id="choose-data-layer"
                 [multiple]="true"
                 bindLabel="name"
                 placeholder="Choose custom data layer input(s)"
                 (clear)="onDataLayersClear($event)"
                 (change)="onDataLayersChange($event)"
                 (remove)="onDataLayerRemove($event)"
                 groupBy="group"
                 [selectableGroupAsModel]="false">
        <ng-template ng-header-tmp>
          <div class="customBoxWrapper">
            <ul class="dataLayerGroupsListCollapsable">
              @for (dataLayerGroup of dataLayersGroups; track dataLayerGroup.name) {
                <li>
                  <span (mouseenter)="onMouseEnterDataLayerGroup(dataLayerGroup)" data-bs-toggle="collapse" class="collapsed" [attr.data-bs-target]="'#' + dataLayerGroup.id">
                    <span>{{dataLayerGroup.name}} ({{dataLayerGroup.count}})</span>
                    <i-feather name="chevron-down" class="xsm"></i-feather>
                  </span>
                  <ul [id]="dataLayerGroup.id" class="columnsList collapse">
                    @for (dataLayer of dataLayers | filterBy:['group']: dataLayerGroup.name; track dataLayer.id) {
                      <li>
                        <span [ngClass]="{active: dataLayer.selected}"
                              (click)="selectDataLayer(dataLayer)"
                              (keydown)="selectDataLayer(dataLayer)"
                              (mouseenter)="onMouseEnterDataLayer(dataLayer)">
                          {{dataLayer.name}}
                        </span>
                      </li>
                    }
                  </ul>
                </li>
              }
            </ul>
            <div class="hintBox" [ngClass]="{'show': showHint}">
              {{this.hintText}}
            </div>

          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          @for (item of items; track item) {
            <div class="ng-value">
              <span class="ng-value-label">{{ item.name }}</span>
              <span class="ng-value-icon right" (click)="clear(item)" (keydown)="clear(item)" aria-hidden="true">×</span>
            </div>
          }
        </ng-template>
        <ng-template ng-typetosearch-tmp></ng-template>
        <ng-template ng-notfound-tmp></ng-template>
        <ng-template ng-loadingtext-tmp></ng-template>
      </ng-select>
    </div>
  </div>
  <div class="col-5">
    <div *ngIf="selectedDataLayers">
      <h5>2. Choose input filters</h5>
      <ul class="selected-data-layers-list">
        <li *ngFor="let dataLayer of selectedDataLayers" class="selected-data-layer">
          <button class="remove" (click)="removeDataLayer(dataLayer)"><i-feather name="x" class="xsm"></i-feather></button>
          <h6 class="label">{{dataLayer.name}}</h6>
          <ul class="selected-filters-list">
            <li *ngFor="let filter of selectedFilters | filterBy: ['parentId']: dataLayer.id" class="selected-item">
              <span class="label me-1">{{filter.title}}</span>
              <app-filter-input *ngIf="filter.type == 'string'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="text"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'number'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="number"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'numberlimited'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="numberlimited"></app-filter-input>
              <app-filter-input *ngIf="filter.type == 'date'" [(ngModel)]="filter.value" [disabled]="filter.disabled" type="date"></app-filter-input>
              <app-lookup *ngIf="filter.type == 'lookup-single'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="single" [disabled]="filter.disabled"></app-lookup>
              <app-lookup *ngIf="filter.type == 'lookup-multi'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="multi" [disabled]="filter.disabled"></app-lookup>
              <app-lookup *ngIf="filter.type == 'lookup-single-nonnull'" [name]="filter.lookupName" [(ngModel)]="filter.value" mode="singlenn" [disabled]="filter.disabled"></app-lookup>
              <button class="remove-filter" (click)="removeFilter(dataLayer, filter)"><i-feather name="x" class="xsm"></i-feather></button>
            </li>
            <li *ngIf="dataLayer.filters?.length > 0">
              <div dropdown style="position: relative;">
                <button dropdownToggle type="button" class="btn btn-sm btn-outline-secondary" data-toggle="dropdown">
                  + Add filter
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu">
                  <li *ngFor="let filter of dataLayer.filters">
                    <a class="dropdown-item" (click)="toogleFilter(dataLayer, filter)">{{filter.title}}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-4">
    <h5>3.  Choose custom data layer filters</h5>
    <div class="input-200">
      <strong>Distance</strong>
        <app-filter-input [(ngModel)]="distanceFilter.value" type="numberlimited" postfix="m"></app-filter-input>
    </div>
    <div class="input-200">
        <strong>Area</strong>
        <app-filter-input [(ngModel)]="areaFilter.value" type="numberlimited" postfix="m<sup>2</sup>"></app-filter-input>
    </div>
    <div class="col-12">
      <b>Merge polygons</b>
    </div>
    <div class="col-12">
      <span>
        The number of individual polygons that meet your selected criteria can be overwhelming.Use the selector below to group selected polygons that are close to each other into a more manageable number of events:
      </span>
    </div>
    <div class="col-12 overflow-y-visible">
      Merge selected polygons that are less than <app-lookup [name]="'MERGERADIUS'" [(ngModel)]="mergeR" mode="singlenn"></app-lookup> from each other.
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="text-start my-1">
      <button (click)="close()" class="btn btn-primary" [disabled]="createLoader">
        Close
      </button>
    </div>
  </div>
  <div class="col-6">
    <div class="text-end my-1">
      <button (click)="create()" class="btn btn-primary" [disabled]="createLoader">
        <span *ngIf="createLoader" class="spinner-border spinner-border-sm mr-1"></span>
        Create
      </button>
    </div>
  </div>

</div>
