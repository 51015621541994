import { ReportsService } from "@/_services/reports.service";
import { ResourcesService } from "@/_services/resources.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MapLayerService } from "@/_services/map-layer.service";
import { MatDialog } from "@angular/material/dialog";
import { GridComponent } from "@/_components/grid";
import { ReportParametersDialogComponent } from "../_components/report-parameters-dialog";
import { JsonResponse } from "../_models/response";
import { ConfirmDialogComponent } from "../_components/confirm-dialog";
import { environment } from "../../environments/environment";

@Component({
  standalone: false,
  selector: 'app-reports',
  providers: [
    {
      provide: ResourcesService,
      useClass: ReportsService
    }
  ],
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public items: any[] = [];
  public gridConfig: any;
  public filter: any;
  public mapDataLoading: boolean = false;
  @ViewChild('dataGrid') dataGrid!: GridComponent;

  constructor(public http: HttpClient,
    public _resourceService: ResourcesService,
    private _mapLayerService: MapLayerService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.gridConfig = {
      _self: this,
      colDefs: [
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'info',
          btnTooltip: 'Info',
          action: this.showParameters,
          paramFields: ['id']
        },
        {
          title: "Report name",
          field: "name",
          sortColumn: "name",
        },
        {
          title: "Mode",
          field: "reportModeLabel"
        },
        {
          title: "Date",
          field: "dateCreated",
          sortColumn: "dateCreated",
        },
        {
          title: "Variable",
          field: "variableName",
          sortColumn: "variable",
        },
        {
          title: "Status",
          field: "status",
          sortColumn: "status",
        },
        {
          title: '',
          btnText: 'View',
          type: 'actionpossible',
          check: 'canView',
          action: this.openDetails,
          paramFields: ['id']
        },
        {
          title: '',
          type: 'action',
          btnText: '',
          btnIconName: 'trash',
          btnTooltip: 'Delete',
          action: this.remove,
          paramFields: ['id']
        }
      ],
      filters: [
        {
          field: "name",
          title: "Report name",
          type: "string"
        },
        {
          field: "dateCreated",
          title: "Date",
          type: "date"
        },
        // {
        //   field: "status",
        //   title: "Status",
        //   type: "lookup",
        //   lookupName: "status"
        // }
      ]
    }
  }

  openDetails(item: any) {
    this.router.navigate(['/report-details/' + item.id]);
  }

  public createReport(): void {
    this.router.navigate(["/report/create"]);

    //const dialogRef = this.dialog.open(CreateReportComponent, {
    //  data: {},
    //  width: '60vw',
    //  height: '80vh',
    //  disableClose: true
    //});
    //dialogRef.afterClosed().subscribe(() => {
    //  this.refreshGrid();
    //});
  }

  refreshGrid() {
    if (this.dataGrid != undefined) {
      this.dataGrid.refreshGrid();
    }
  }

  showParameters(item: any) {
    this.dialog.open(ReportParametersDialogComponent, {
      data: {
        entity: item,
        variable: item.variable
      }
    });
  }

  remove(item?: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        icon: "alert-triangle",
        message: "Are you sure to delete \"" + item.name + "\" report?",
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.delete<JsonResponse>(`${environment.apiUrl}/api/Report/Delete?id=${item.id}`).subscribe(result => {
          if (result) {
            if (this.dataGrid != undefined) {
              this.dataGrid.refreshGrid();
            }
          }
        });
      }
    });
  }

  ngOnInit(): void {
  }
}
