import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DataDialogData {
  title: string | null;
  dataTable: any | null;
}

@Component({
  standalone: false,
  selector: 'data-dialog',
  styleUrls: ['data-dialog.component.scss'],
  templateUrl: 'data-dialog.component.html',
})
export class DataDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataDialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
