<h1 mat-dialog-title>Data layer parameters used for generating</h1>
<div mat-dialog-content>
  <table class="table table-sm table-bordered w-100">
    <tbody>

      <tr *ngIf="entity.creationParameters != null && entity.creationParameters.distanceFilter != null && entity.creationParameters.distanceFilter.value.valuePrimary != null">
        <td>Horizontal distance [m] <br /><small></small></td>
        <td><app-filter-as-text [value]="entity.creationParameters.distanceFilter.value"></app-filter-as-text></td>
      </tr>
      <tr *ngIf="entity.creationParameters != null && entity.creationParameters.areaFilter != null && entity.creationParameters.areaFilter.value.valuePrimary != null">
        <td>Area [m^2] <br /><small></small></td>
        <td><app-filter-as-text [value]="entity.creationParameters.distanceFilter.value"></app-filter-as-text></td>
      </tr>
      <tr *ngIf="entity.creationParameters != null && entity.creationParameters.mergeDistance > 0">
        <td>Events merge radius <br /><small></small></td>
        <td>{{entity.creationParameters.mergeDistance}} m</td>
      </tr>
      <tr>
        <td colspan="2">
          <table class="table table-sm table-striped mb-0 w-100" *ngFor="let layer of entity.creationParameters.selectedLayers">
            <tr>
              <td>
                <h6>Source layer:<strong>{{layer.name}}</strong></h6>
                {{layer.description}}
              </td>
            </tr>
            <tr><td>Layer parameters</td></tr>
            <tr>
              <td>
                <table class="table table-sm table-striped mb-0 table-variable-params w-100">
                  <tbody>
                    <tr *ngFor="let filter of layer.filters">
                      <td class="text-end">{{filter.title}}</td>
                      <td>
                        <ng-container *ngIf="!['lookup-type', 'lookup-multi', 'date'].includes(filter.type)">
                          <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                        </ng-container>
                        <ng-container *ngIf="filter.type == 'date'">
                          <app-filter-as-date  [value]="filter.value"></app-filter-as-date>
                        </ng-container>
                        <span *ngIf="['lookup-type', 'lookup-multi'].includes(filter.type)"><app-lookup-as-text name="INCIDENTTYPE" [showValues]="filter.value.values" /></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr *ngIf="entity.creationParameters?.date1?.valuePrimary">
              <td *ngIf="entity.reportMode != 2">Date</td>
              <td *ngIf="entity.reportMode == 2"> Date range for pass 1</td>
              <td>
                <app-filter-as-date [value]="entity.creationParameters.date1"></app-filter-as-date>
              </td>
            </tr>
            <tr *ngIf="entity.creationParameters?.date2?.valuePrimary">
              <td>Date range for pass 2</td>
              <td>
                <app-filter-as-date [value]="entity.creationParameters.date2"></app-filter-as-date>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr *ngIf="entity.creationParameters != null && entity.creationParameters.assetFilter != null">
        <td>Asset filters</td>
        <td>
          <table class="table table-sm table-striped mb-0">
            <tbody>
              <ng-container *ngFor="let filter of assetFilter">
                <tr *ngIf="filter.value.valuePrimary != null">
                  <td class="text-end">{{filter.title}}</td>
                  <td>
                    <app-filter-as-text [value]="filter.value"></app-filter-as-text>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn btn-secondary">Close</button>
</div>
