import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  standalone: false,
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss']
})
export class FilterPanelComponent {
  public _config: any;
  @Input() set config(value: any)  {
    if(value !== undefined && Object.keys(value).length > 0) {
      this._config = value;
      this.readFilter()
    }
  }
  @Input() showHideBtn: boolean = false;
  @Input() filterAsArray: boolean = false;
  @Output() filterEvent = new EventEmitter<any>();
  public filter: any;

  constructor() {}

  applyFilter() {
    this.readFilter()
  }
  clearFilter() {
    this.filter = {};
    if(this._config != undefined) {
      this._config.forEach((filter: any) => {
        if(filter?.value != undefined && filter.value != '') {
          filter.value = undefined;
        }
      })
    }
    this.filterEvent.emit(this.filter);
  }
  readFilter(): void {
    if (this._config !== undefined) {
      if(this.filterAsArray) {
        this.filter = {filters: []};
        this._config.forEach((filter: any) => {
          if (filter.value != undefined &&
            filter.value != '' &&
            filter.value != null &&
            ((filter.value?.valuePrimary != null && !Number.isNaN(filter.value?.valuePrimary)) || filter.value?.values != undefined)) {
            this.filter.filters.push(filter);
          }
        })
      }
      else {
        this.filter = {};
        this._config.forEach((filter: any) => {
          if (filter.value != undefined && filter.value != '') {
            this.filter[filter.field] = filter.value;
          }
        })
      }
      this.filterEvent.emit(this.filter);
    }
  }
}
