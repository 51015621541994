import { ResourcesService } from "@/_services/resources.service";
import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserAccountsService } from "@/_services/user-accounts.service";

@Component({
  standalone: false,
  selector: 'app-users',
  providers: [
    {
      provide: ResourcesService,
      useClass: UserAccountsService
    }
  ],
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public items: any[] = [];
  public gridConfig: any;
  public mapDataLoading: boolean = false;
  private http: HttpClient;

  constructor(http: HttpClient, private dialog: MatDialog, public _resourceService: ResourcesService, private router: Router) {
    this.gridConfig = {
      _self: this,
      colDefs: [
        //{
        //  title: "Id",
        //  field: "id",
        //  sortColumn: "Id"
        //},
        {
          title: "Name",
          field: "name",
          sortColumn: "Username",
        },
        {
          title: "Email",
          field: "email",
          sortColumn: "Email",
        },
        {
          title: "PhoneNumber",
          field: "phoneNumber",
          sortColumn: "PhoneNumber",
        },
        {
          title: "Company",
          field: "company",
          sortColumn: "Company",
        },
        //{
        //  title: '',
        //  type: 'action',
        //  btnText: 'Details',
        //  action: this.openDetails,
        //  paramFields: ['id']
        //}
      ],
      filters: [
        {
          field: "id",
          title: "Id",
          type: "number"
        }
      ]
    }
    this.http = http;
  }

  ngOnInit(): void {
  }

  openDetails(item: any) {
    this.router.navigate(['/user-details/' + item.id]);
  }
}
