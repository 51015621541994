import {HttpClient} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {DialogService} from "@/_services/dialog.service";
import {environment} from "@envs/environment";
import {Router} from "@angular/router";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {NgWizardConfig, NgWizardService, TOOLBAR_POSITION} from "ng-wizard";
import {JsonCollectionResponse, JsonItemResponse} from "@/_models/response";
import { v4 as uuidv4 } from 'uuid';
import {of, shareReplay, timeout} from "rxjs";
import {catchError} from "rxjs/operators";

@Component({
  standalone: false,
  selector: 'report-create',
  providers: [],
  styleUrls: ['report-create.component.scss'],
  templateUrl: 'report-create.component.html',
})
export class ReportCreateComponent implements OnInit {
  public wizardConfig: NgWizardConfig = {
    toolbarSettings: {
      toolbarPosition: TOOLBAR_POSITION.none,
      showNextButton: false,
      showPreviousButton: false
    },
    anchorSettings: {
      removeDoneStepOnNavigateBack: true
    }
  };
  public reportName: any;
  public fakeData: any = [{}];
  public previewGridConfig: any = {
    _self: this,
    colDefs: []
  };
  public createLoader: boolean = false;
  public compareLoader: boolean = false;
  http: HttpClient;
  public selectedColumns: any = [];
  public dataLayers: any = [];
  public dataLayersToCompare: any;
  public isAnyLayerSelected: boolean = false;
  public assetLayer: any = {};

  constructor(
    private readonly _http: HttpClient,
    public dialogService: DialogService,
    private readonly router: Router,
    private readonly ngWizardService: NgWizardService,
  ) {
    this.http = _http;
  }
  ngOnInit(): void {
    this.http.get<JsonCollectionResponse<any>>(`${environment.apiUrl}/api/DataLayer/GetDataLayerTypes`).subscribe(result => {
      if (result.ok && result.items.length > 0) {
        result.items.forEach((dataLayerType: any) => {
          dataLayerType.guid = uuidv4();
          this.dataLayers = [...this.dataLayers, dataLayerType];
        });
      }
    });
    this.http.get<JsonItemResponse<any>>(`${environment.apiUrl}/api/DataLayer/GetAssetColumns`).subscribe(result => {
      if (result.ok && result.item) {
        this.assetLayer = result.item;
      }
    });
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }
  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }
  goToSelectColumns() {
    this.ngWizardService.next();
    this.selectedColumns =
      [
        ...this.assetLayer.colDefs.map(
          (colDef: any) => {
            colDef.parentName = this.assetLayer.name;
            colDef.selected = true;
            return colDef;
          }
        ),
        ...this.dataLayers.reduce((filtered: any, dataLayer: any) => {
          if(dataLayer.selected) {
            if (dataLayer.mode === "0" && dataLayer.assetViewColumns != null) {
              filtered = [...filtered, ...dataLayer.assetViewColumns.map(
                (colDef: any) => {
                  colDef.parentName = dataLayer.name;
                  colDef.selected = true;
                  return colDef;
                }
              )]
            }
            if (dataLayer.mode === "1" && dataLayer.eventViewColumns != null) {
              filtered = [...filtered, ...dataLayer.eventViewColumns.map(
                (colDef: any) => {
                  colDef.parentName = dataLayer.name;
                  colDef.selected = true;
                  return colDef;
                }
              )]
            }
            let passOrCompare = dataLayer.availableFilters?.find((filter: any) => {return filter.type == "pass_or_compare"})
            if (passOrCompare && passOrCompare.dateMode == "1" && dataLayer.comparativeViewColumns != null) {
              filtered = [...filtered, ...dataLayer.comparativeViewColumns.map(
                (colDef: any) => {
                  colDef.parentName = dataLayer.name;
                  colDef.selected = true;
                  return colDef;
                }
              )]
            }
          }
          return filtered;
        }, [])
      ]
  }
  goToSummary(){
    this.previewGridConfig.colDefs = [];
    this.selectedColumns.forEach((column: any) => {
      if(column.selected) {
        this.previewGridConfig.colDefs.push({
          title: column.title + '<br/><small class="subtitle">(' + column.parentName + ')</small>',
          field: column.field,
        })
      }
    })
    this.ngWizardService.next();
  }
  updateLayerSelected(layer: any): void {
    let _self = this;
    setTimeout(function () {
      if(layer.selected && layer.mode == undefined) {
        layer.mode = "0";
      }
      if(!layer.selected) {
        layer.mode = undefined;
      }
      _self.isAnyLayerSelected = _self.dataLayers.find((dataLayer:any) => {return dataLayer.selected && (dataLayer.mode === "0" || dataLayer.mode === "1")})
    }, 100)
  }
  selectLayerMode(layerType: any): void {
    layerType.selected = true;
    this.updateLayerSelected(layerType);
  }
  dropColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
  }
  reduceFilters(filtered: any, filter: any) {
    switch (filter.type) {
      case "pass_or_compare":
        filtered.push({
          Field: filter.field + 'Group',
          Title: filter.title,
          Type: filter.type,
          Value: {Values: filter.group},
        })
        filtered.push({
          Field: filter.field,
          Title: filter.title,
          Type: filter.type,
          Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
        })
        if(filter.dateMode == 1) {
          filtered.push({
            Field: filter.field + 'Group2',
            Title: filter.title,
            Type: filter.type,
            Value: {Values: filter.group2},
          })
          filtered.push({
            Field: filter.field + '2',
            Title: filter.title,
            Type: filter.type,
            Value: filter.value2,
          })
        }
        break
      default:
        filtered.push({
          Field: filter.field,
          Title: filter.title,
          Type: filter.type,
          Value: (filter.value.operator != undefined) ? filter.value : {Values: filter.value},
        })
    }
    return filtered;
  }
  create() {
    let columnOrder = 1;
    this.selectedColumns.forEach((column: any) => {
      column.order = columnOrder
      columnOrder++;
    })
    let selectedDataLayers: DataLayerWithColumns[] = [];
    let index = this.assetLayer?.colDefs.findIndex((x: any) => { return x.selected; })
    if(index > -1) {
      selectedDataLayers.push({
        Id: this.assetLayer.id,
        Guid: "",
        CompareToId: undefined,
        Name: this.assetLayer.name,
        Schema: "",
        JoinType: "",
        EventLayer: false,
        DataLayerColumns:
          this.assetLayer?.colDefs.reduce((filtered: any, x: any) => {
            if (x.selected) {
              filtered.push({
                Name: x.field,
                DisplayName: x.title,
                Type: x.type,
                Code: x.code,
                Alias: "",
                Hint: "",
                Order: x.order,
              })
            }
            return filtered;
          }, [])
      });
    }
    let createDataLayerRequests: CreateDataLayerRequest[] = [];
    this.dataLayers.forEach((dataLayer: any) => {
      if(dataLayer.selected) {
        createDataLayerRequests.push({
          Name: dataLayer.name,
          Guid: dataLayer.guid,
          EventLayer: dataLayer.mode === "1",
          SelectedLayers: [
            {
              Id: 0,
              Guid: dataLayer.guid,
              Name: dataLayer.name,
              Date: undefined,
              GroupId: undefined,
              Columns: [],
              Table: "",
              Filters: dataLayer.availableFilters?.reduce(this.reduceFilters,[]),
            }
          ]
        })
        selectedDataLayers.push({
          Id: 0,
          Guid: dataLayer.guid,
          CompareToId: undefined,
          Name: dataLayer.name,
          Schema: "",
          JoinType: "",
          EventLayer: dataLayer.mode === "1",
          DataLayerColumns: [
            ...dataLayer.eventViewColumns.reduce((filtered: any, x: any) => {
              if(x.selected) {
                filtered.push( {
                  Name: x.field,
                  DisplayName: x.title,
                  Type: x.type,
                  Code: x.code,
                  Alias: "",
                  Hint: "",
                  Order: x.order,
                })
              }
              return filtered;
            },[]),
            ...dataLayer.assetViewColumns.reduce((filtered: any, x: any) => {
              if(x.selected) {
                filtered.push( {
                  Name: x.field,
                  DisplayName: x.title,
                  Type: x.type,
                  Code: x.code,
                  Alias: "",
                  Hint: "",
                  Order: x.order,
                })
              }
              return filtered;
            },[]),
          ]
        })
      }
    })
    let params = {
      CreateReportViewRequest: {
        Name: this.reportName,
        SelectedDataLayers: selectedDataLayers
      },
      CreateDataLayerRequest: createDataLayerRequests
    };
    if (!this.createLoader) {
      this.createLoader = true;
      this.http.post<any>(`${environment.apiUrl}/api/Report/FullCreateReport/`, params)
        .pipe(
          shareReplay(1), // Ensures the request is not canceled
          catchError((error) => {
            console.error('Request failed:', error);
            return of(null); // Suppress errors
          })
        )
        .pipe(
          timeout(5000), // Terminate the subscription if it exceeds the timeout 5s
          catchError((error) => {
            if (error.name === 'TimeoutError') {
              this.router.navigate(["/reports"]);
            }
            return of(null);
          })
        )
        .subscribe(result => {
        if (result.ok) {
          this.router.navigate(["/reports"]);
        }
        else {
          this.dialogService.displayErrorMessages(result.messages);
        }
        this.createLoader = false;
      });
    }
  }
  close() {
    this.router.navigate(["/report/list"]);
  }
}

class DataLayerWithColumns {
  Id: number | undefined;
  Guid: string = "";
  CompareToId: number | undefined;
  Name: string = "";
  Schema: string = "";
  JoinType: string = "";
  EventLayer: boolean = false;
  DataLayerColumns: DataLayerColumn[] = [];
}

class DataLayerColumn {
  Name: string = "";
  DisplayName: string = "";
  Type: string = "";
  Code: string = "";
  Alias: string = "";
  Hint: string = "";
  Order: number = 0;
}
class SingleDataLayerInfo {
  Id: number | undefined;
  Guid: string = "";
  Name: string = "";
  Table: string = "";
  Date: any = { operator: 11, valuePrimary: null, valueSecondary: null };
  GroupId: number | undefined;
  Columns: string[] = [];
  Filters: GenericFilterDefinition[] = [];
}

class GenericFilterDefinition {
  Field: string = "";
  Type: string = "";
  Title: string = "";
  Value: any = { operator: null, valuePrimary: null, valueSecondary: null };
}
class  CreateDataLayerRequest {
  Name: string = "";
  Guid: string = "";
  EventLayer: boolean = false;
  SelectedLayers: SingleDataLayerInfo[] = [];
}
