import { ResourcesService } from "@/_services/resources.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetsImportComponent } from '@/_components/assets-import';
import { MapLayerService } from "@/_services/map-layer.service";
import { environment } from "@envs/environment";
import { MapLayer } from "@/map/models";
import VectorSource from "ol/source/Vector";
import WKB from "ol/format/WKB";
import { Style, Stroke, Fill, Text as olText } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import { Router } from "@angular/router";
import { Color } from "ol/color";
import { InfoDialogComponent } from "@/_components/info-dialog";
import { IncidentsService } from "@/_services/incidents.service";
import { OlMapService } from "@/_services/ol-map.service";
import { IncidentsByAssetService } from "@/_services/incidents-by-asset.service";
import { GridComponent } from "@/_components/grid";
import { AssetsService } from "@/_services/assets.service";
import { CompanyConfigService } from "@/_services/company.config";
import { defaultEventsGridConfigColDef, defaultAssetViewGridConfigColDef, defaultEventsFilters } from "@/_constants/events";
import { cloneDeep } from 'lodash';
import { takeUntil } from "rxjs";
import { AuthenticationService } from "@/_services";
@Component({
  standalone: false,
  selector: 'app-assets-monitoring',
  providers: [
    {
      provide: ResourcesService,
      useClass: IncidentsService
    },
    {
      provide: IncidentsByAssetService
    },
    {
      provide: AssetsService
    }
  ],
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  public service: ResourcesService;
  private _incidentService: ResourcesService;
  private _incidentByAssetService: ResourcesService;
  public items: any[] = [];
  public gridConfig: any;
  public mapDataLoading: boolean = false;
  private http: HttpClient;
  public filterConfig: any;
  private _filter: any;
  private _companyConfig: any;
  public selectedCategory: string = "";
  public viewType: string = 'incident';
  public mode: string = 'table';
  public dataSource: string = 'incidents';
  private eventsFilters: any;
  private defaultEventsFilters = defaultEventsFilters;
  private assetViewGridConfig: any = { _self: this, colDefs: defaultAssetViewGridConfigColDef }
  private defaultAssetViewGridConfig = cloneDeep(this.assetViewGridConfig);
  public dataGridLoadData: boolean = false;
  @ViewChild('dataGrid') dataGrid!: GridComponent;
  set filter(value: any) {
    this._filter = value;
    if (this.dataGridLoadData) {
      if (this.selectedCategory == "incidents") {
        if (!this._companyConfig?.assetAsPolygons) {
          this.makeLayerIncidents();
          this.makeLayerImpactedAssets();
        }
      }
      else if (this.selectedCategory == "monitoring") {
        this.makeLayerAssetsMonitoring();
      }
    }
  }
  get filter() {
    return this._filter;
  }
  constructor(http: HttpClient,
    private dialog: MatDialog,
    public _resourceService: ResourcesService,
    public _incidentsByAssetService: IncidentsByAssetService,
    public _assetsService: AssetsService,
    private _mapLayerService: MapLayerService,
    private router: Router,
    private _olMapService: OlMapService,
    private _companyConfigService: CompanyConfigService,
    private _authenticationService: AuthenticationService) {
    this.http = http;
    this.service = _resourceService;
    this._incidentService = _resourceService;
    this._incidentByAssetService = _incidentsByAssetService;
    this._assetsService = _assetsService;
  }

  private loadAssetsOnMap() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "assets";
    this._assetsService.getNonPagedList(this.filter, {}).pipe(takeUntil(this._authenticationService.clearSession)).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.name = "Assets";
        layer.visible = true;
        layer.total = result.items.length;
        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Name': value.name,
            }, undefined, 2)
          })

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.pointColor,
                }),
                stroke: new Stroke({
                  color: value.properties.pointOutlineColor,
                  width: value.properties.pointWidth,
                }),
              })
            );
          }
          else if (['Polygon', 'MultiPolygon','GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
    return layer;
  }

  public setMode(mode: string, zoomToLayer: boolean = false) {
    this.mode = mode;
    if (mode == 'map') {
      this._olMapService.addEvent('incidents', 'updateSize', {});
      if (zoomToLayer) {
        if (this.viewType == 'asset') {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents_to_asset" })
        }
        else if (this.viewType == 'incident') {
          this._mapLayerService.addEvent('incidents', 'zoomToLayer', { layerId: "incidents" })
        }
      }
    }
  }

  public setViewType(viewType: string, forceUpdateFilter: boolean = false, reload: boolean = true) {
    let oldViewType = this.viewType;
    this.viewType = viewType;
    if (this.dataGrid != undefined) {
      this.dataGrid.loading = true;
      this.dataGrid.clearSort();
    }
    if (viewType == 'incident') {
      this.service = this._incidentService;
    }
    else if (viewType == 'asset') {
      this.gridConfig = this.assetViewGridConfig;
      this.service = this._incidentByAssetService;
    }
    this.dataGridLoadData = true;
    let self = this;
    setTimeout(function () {
      self.dataGrid.unsubscribeService()
        .then(() => {
          self.dataGrid.goToPage(0, reload)
          self.setIncidentsGridConfig();
        });
    }, 50);
  }

  private makeLayerAssetsMonitoring() {
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "assetsMonitoring";
    var makeMapText = this.makeMapText;

    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": -1,
      "updateCount": true,
      "sort": {},
      "filter": this.filter
    }

    this.http.post<any>(`${environment.apiUrl}/api/AssetMonitoring/GetNonPagedList`, params).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.name = "Assets at risk";
        layer.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });

          var text: olText | undefined;
          if (value.incidentsAmount > 0) {
            text = makeMapText(value.incidentsAmount.toString(), value.properties.textColor);
          }

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                }),
                text: text,
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.pointColor,
                }),
                stroke: new Stroke({
                  color: value.properties.pointOutlineColor,
                  width: value.properties.pointWidth,
                }),
              })
            );
          }
          else if (['Polygon', 'MultiPolygon','GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer);
        this.mapDataLoading = false;
        //this.router.navigate(['/map']);
      },
      error: (error: Error) => console.error(error)
    });
    return layer;
  }

  private makeMapText(text: string, color: Color = [0, 0, 0], size: number = 14) {
    return new olText({
      //textAlign: normal,
      //textBaseline: baseline,
      font: size + 'px arial',
      text: text,
      fill: new Fill({ color: color }),
      //stroke: new Stroke({ color: value.properties.polygonOutlineColor, width: value.properties.polygonOutlineWidth }),
      offsetX: -size,
      offsetY: -size,
      //placement: placement,
      //maxAngle: maxAngle,
      overflow: true,
      rotation: 0,
    });
  }

  private makeLayerIncidents() {
    this._mapLayerService.addEvent('incidents', 'showLoader', {})
    let layer = new MapLayer();
    this.mapDataLoading = true;
    layer.id = "Incidents";
    this._incidentService.getNonPagedList(this.filter, {}).pipe(takeUntil(this._authenticationService.clearSession)).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer.id = "incidents";
        layer.name = "Events";
        layer.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          _feature.setId(value.id);
          _feature.setProperties({
            popup: JSON.stringify({
              'Distance': value.distance,
              'Height': value.height,
              'Class': value.class,
              'NDVI': value.ndvi
            }, undefined, 2)
          })

          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: value.properties.lineColor,
                  width: value.properties.lineWidth,
                })
              })
            );
          }
          else if (['Point', 'MultiPoint'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.pointColor,
                }),
                stroke: new Stroke({
                  color: value.properties.pointOutlineColor,
                  width: value.properties.pointWidth,
                }),
              })
            );
          }
          else if (['Polygon', 'MultiPolygon','GeometryCollection'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                fill: new Fill({
                  color: value.properties.polygonColor,
                }),
                stroke: new Stroke({
                  color: value.properties.polygonOutlineColor,
                  width: value.properties.polygonOutlineWidth,
                }),
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });

        layer.olLayer = new VectorLayer({ source: _vectorSource });
        layer.olLayer.set('layerId', layer.id);
        this._mapLayerService.addLayer(layer, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }
  private makeLayerImpactedAssets() {
    let layer2 = new MapLayer();
    this.mapDataLoading = true;
    layer2.id = "incidents_to_asset";
    this._incidentsByAssetService.getIncidentsMappedToAssets(this.filter, {}).pipe(takeUntil(this._authenticationService.clearSession)).subscribe({
      next: (result: any) => {
        let mapData = result.items;
        layer2.id = "incidents_to_asset";
        layer2.name = "Impacted assets";
        layer2.visible = true;

        let _vectorSource = new VectorSource();
        let _wkbFormatter = new WKB();

        mapData.forEach(function (value: any) {
          let _feature = _wkbFormatter.readFeature(value.wkb, {
            dataProjection: 'EPSG:3857',
            featureProjection: 'EPSG:3857',
          });
          //_feature.setId(value.id);
          var geometry = _feature.getGeometry();
          let geometryType = geometry?.getType();
          if (['LineString', 'MultiLineString'].includes(geometryType!)) {
            _feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "#ff9100",
                  width: 4,
                })
              })
            );
          }
          _vectorSource.addFeature(_feature);
        });
        layer2.olLayer = new VectorLayer({ source: _vectorSource });
        this._mapLayerService.addLayer(layer2, true);
        this.mapDataLoading = false;
        this._mapLayerService.addEvent('incidents', 'hideLoader', {})
      },
      error: (error: Error) => console.error(error)
    });
  }

  downloadExcel() {
    let params = {
      "operationType": "SEARCH",
      "startRow": 0,
      "pageSize": 20,
      "updateCount": true,
      "sort": {},
      "filter": this.filter
    }

    let endpoint = 'GetExcelExportFile';
    if (this.viewType == 'asset') {
      endpoint = 'GetExcelAssetExportFile';
    }

    this.http.post<any>(`${environment.apiUrl}/api/Incident/GetExcelExport`, params).subscribe({
      next: (result: any) => {
        window.open(`${environment.apiUrl}/api/Incident/` + endpoint + `?key=` + result.item, '_blank');
      },
      error: (error: Error) => console.error(error)
    });
  }

  ngOnInit(): void {
    this._companyConfigService.configStore.subscribe(
      config => {
        if (this._companyConfigService.configLoaded) {
          this._companyConfig = config;
          this.eventsFilters = cloneDeep(this.defaultEventsFilters);
          this.assetViewGridConfig = this.defaultAssetViewGridConfig;
          if (this._companyConfig?.assetAsPolygons) {
            this.assetViewGridConfig.colDefs.map(function (column: any) {
              (column.field === "estimatedAssetLengthImpacted") && (column.title = "Estimated area asset impacted [m<sup>2</sup>]");
              (column.field === "estimatedAssetPercentageImpacted") && (column.title = "Estimated asset area impacted [%]");
              (column.field === "assetLength") && (column.title = "Total area of asset [m<sup>2</sup>]");
            });
            this.eventsFilters.splice(this.eventsFilters.findIndex((item: any) => item.field === "assetDistance"), 1)
          }
          if (!this._companyConfig?.showAbsoluteDistance) {
            this.eventsFilters.splice(this.eventsFilters.findIndex((item: any) => item.field === "assetDistance3d"), 1)
            this.assetViewGridConfig.colDefs.splice(this.assetViewGridConfig.colDefs.findIndex((item: any) => item.field === "minimalDistance3DToAsset"), 1)
          }
          if (this._companyConfig?.defaultEventViewType) {
            this.viewType = this._companyConfig.defaultEventViewType;
          }
          this.loadAssetsOnMap();
          this.setViewType(this.viewType, false, false);
        }
      }
    );
  }

  showOnMap(item?: any) {
    if (item != null) {
      if (this.viewType == "incident") {
        this._olMapService.addEvent('incidents', 'selectFeature', { layerId: 'incidents', featureId: item.id })
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'incidents', featureId: item.id })
      }
      else if (this.viewType == "asset") {
        this._mapLayerService.addEvent('incidents', 'zoomToFeature', { layerId: 'assets', featureId: item.id })
      }
      this.setMode("map");
    }
  }

  setMonitoringGridConfig() {
    this.dataSource = "monitoring";
    this.selectedCategory = "monitoring";
    if (this.viewType == 'incident') {
      this.gridConfig = {
        _self: this,
        colDefs: [
          {
            title: '',
            type: 'action',
            btnText: '',
            btnIconName: 'map',
            action: this.showOnMap,
            paramFields: ['id']
          },
          {
            title: "AssetName",
            field: "assetName",
            sortColumn: "Asset.Name",
          },
          {
            title: "Hrs wind speed > 15m/s",
            field: "wind",
            sortColumn: "windhours",
          },
          {
            title: "Hrs temp < -10 °C",
            field: "temp1",
            sortColumn: "Temp1",
          },
          {
            title: "Hrs temp < 0 °C",
            field: "temp2",
            sortColumn: "Temp2",
          },
          {
            title: "Hrs temp > 30 °C",
            field: "temp3",
            sortColumn: "Temp3",
          },
          {
            title: "Days rainfall > 15mm",
            field: "rain",
            sortColumn: "Rain",
          },
          {
            title: "Date",
            field: "date",
            sortColumn: "Date",
          }
        ]
      }
    }
    this.filterConfig = [
      {
        title: "Climate Conditions",
        type: "group",
        field: "climate-conditions",
        filters: [
        ]
      },
      {
        field: "windhours",
        title: "Hrs wind speed > 15m/s",
        type: "number",
        value: { operator: 1, valuePrimary: 15, valueSecondary: null }
      },
      {
        field: "temp1",
        title: "Hrs temp < -10 °C",
        type: "number",
        value: { operator: 1, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "temp2",
        title: "Hrs temp < 0 °C",
        type: "number",
        value: { operator: 1, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "temp3",
        title: "Hrs temp > 30 °C",
        type: "number",
        value: { operator: 1, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "rain",
        title: "Days rainfall > 15mm",
        type: "number",
        value: { operator: 1, valuePrimary: null, valueSecondary: null }
      },
      {
        field: "IncidentClassId",
        title: "Type",
        type: "lookup-multi",
        lookupName: "INCIDENTTYPE",
      },
      {
        field: "AssetName",
        title: "Asset name",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        field: "AssetCategory",
        title: "Asset Category",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        field: "assetlocation",
        title: "Asset location",
        type: "string",
        value: { operator: 7, valuePrimary: "", valueSecondary: null }
      },
      {
        field: "Date",
        title: "date",
        type: "date"
      },
      {
        field: "area",
        title: "Area",
        type: "number",
        value: { operator: 6, valuePrimary: null, valueSecondary: null }
      },
    ]
  }

  setIncidentsGridConfig(updateFilter = true) {
    this.dataSource = "incidents";
    this.selectedCategory = "incidents";
    if (this.viewType == 'incident') {
      this.gridConfig = { _self: this, colDefs: defaultEventsGridConfigColDef.map(a => { return { ...a } }) }
      if (this._companyConfig?.assetAsPolygons) {
        this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "distance"), 1)
        this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "area"), 1)
      }
      if (!this._companyConfig?.showAbsoluteDistance) {
        this.gridConfig.colDefs.splice(this.gridConfig.colDefs.findIndex((item: any) => item.field === "distance3D"), 1)
      }
    }
    if (updateFilter) {
      this.filterConfig = this.eventsFilters;
    }
  }
}
