import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  standalone: false,
  selector: 'report-parameters-dialog',
  styleUrls: ['report-parameters-dialog.component.scss'],
  templateUrl: 'report-parameters-dialog.component.html',
})
export class ReportParametersDialogComponent {
  public variable: any;
  public entity: any;
  public assetFilter: any;
  constructor(
    public dialogRef: MatDialogRef<ReportParametersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.variable = data.variable;
    this.entity = data.entity;
    this.assetFilter = [
      {
        field: "name",
        title: "Asset Name",
        type: "string",
      },
      {
        title: "Asset type",
        field: "assetType",
        type: "string",
      },
      {
        field: "assetCategory",
        title: "Asset Category",
        type: "string",
      },
      {
        title: "Elevation",
        field: "elevation",
        type: "number",
      },
      {
        field: "assetLocation",
        title: "Asset Location",
        type: "string",
      },
      {
        field: "assetSublocation",
        title: "Location subgroup",
        type: "string",
      },
      {
        title: "Start Point",
        field: "startName",
        type: "string",
      },
      {
        title: "End Point",
        field: "endName",
        type: "string",
      },
    ];
    let self = this;
    if (this.entity.parameters != null && "assetFilter" in this.entity.parameters && this.entity.parameters.assetFilter != null) {
      this.assetFilter.forEach(function (filter: any) {
        filter.value = self.entity.parameters?.assetFilter[filter.field]
      })
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
