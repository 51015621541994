<div class="screen">
  <div class="d-flex flex-row align-items-center justify-content-between pt-2 pb-2 mb-2 border-bottom">
    <button class="btn btn-params btn-primary me-1" (click)="showParameters()" title="Show parameters">
      <i-feather name="info" class="sm"></i-feather>
    </button>
    <h1 class="h6 tab-header" style="width:50%;">Report details: <span *ngIf="!loading">{{entity.name}}</span><ngx-skeleton-loader *ngIf="loading" count="1" [theme]="{margin: 0, display: 'inline-block', height: '24px', 'vertical-align': '-2px'}"></ngx-skeleton-loader></h1>
    <div *ngIf="viewType =='1' ||viewType =='0' ">
      <span>
        View by:
      </span>
      <mat-button-toggle-group name="viewType" [value]="viewType" hideSingleSelectionIndicator="true" class="view-toggle">
        <mat-button-toggle class="view-toggle-button" value="1" (keydown)="setViewType(1)" (click)="setViewType(1)">Event</mat-button-toggle>
        <mat-button-toggle class="view-toggle-button" value="0" (keydown)="setViewType(0)" (click)="setViewType(0)">Asset</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div>
      <span>
        View mode:
      </span>
      <mat-button-toggle-group name="mode" [value]="mode" hideSingleSelectionIndicator="true" class="view-toggle">
        <mat-button-toggle class="view-toggle-button" value="table" (keydown)="setMode('table')" (click)="setMode('table')">Table</mat-button-toggle>
        <mat-button-toggle class="view-toggle-button" value="map" (keydown)="setMode('map', true)" (click)="setMode('map', true)">Map</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <button class="btn btn-warning btn-sm download-excel-btn mx-1" *ngIf="!loading && entity.reportMode!=2" [disabled]="!(dataGrid.totalCount > 0)" (click)="downloadExcel()">
      <i-feather name="file" class="xsm"></i-feather>
      Export
    </button>
    <button class="btn btn-warning btn-sm download-excel-btn mx-1" *ngIf="!loading && entity.reportMode!=2" [disabled]="!(dataGrid.totalCount > 0)" (click)="downloadGeojson()">
      <i-feather name="file" class="xsm"></i-feather>
      Export Geojson
    </button>
  </div>
  <div class="d-flex flex-row align-items-center">
    <button class="btn btn-outline-secondary btn-sm" style="margin-right:5px" title="Configure" (click)="openGridConfig()" *ngIf="!loading">
      <i-feather name="grid" class="xsm"></i-feather>
    </button>
    <app-filter-panel [config]="filterConfig" (filterEvent)="filter = $event" [showHideBtn]="true" [filterAsArray]="filterAsArray"></app-filter-panel>
  </div>
  <div class="data-grid h-100">
    <app-grid #dataGrid class="w-100" [data]="items" [config]="gridConfig" [service]="service" [filter]="filter" [parentId]="id" [hidden]="mode!='table'" [loadData]="dataGridLoadData" [showHideFiltersBtn]="true"></app-grid>
    <app-map class="w-100 h-100 pt-2" [showLayers]="true" [hidden]="mode!='map'" name="incidents"></app-map>
  </div>
</div>
