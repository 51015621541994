import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  standalone: false,
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  public items: MenuItem[] = [];
  constructor(private readonly router: Router) {
    this.items = [
      {
        routerLink: "/assets",
        icon: "folder",
        name: "Assets"
      },
      {
        routerLink: "/data-sources",
        icon: "image",
        name: "Data Sources"
      },
      {
        routerLink: "/data-layers",
        icon: "file",
        name: "Data Layers"
      },
      {
        routerLink: "/reports",
        icon: "file-text",
        name: "Reports"
      }
    ];
  }
  public onMenuClick(item: MenuItem): void {
    this.router.navigate([item.routerLink]);
  }
}

interface MenuItem {
  routerLink: string,
  icon: string,
  name: string,
  fake?: boolean,
  disabled?: boolean
}
