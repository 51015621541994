export const defaultReportEventViewGridConfigColDef = [
  {
    title: '',
    originalTitle: 'Show on Map',
    type: 'action',
    btnText: '',
    btnIconName: 'map',
    btnTooltip: 'Show on map',
    action: 'showOnMap',
    paramFields: ['id'],
    editable: true,
    visible: true,
  },
  {
    title: "Event Id",
    originalTitle: "Event Id",
    field: "incidentName",
    sortColumn: "IncidentName",
    editable: true,
    visible: true,
    filter: {
      field: "IncidentName",
      title: "Event Id",
      type: "string",
      value: { operator: 7, valuePrimary: "", valueSecondary: null }
    }
  },
  {
    title: "Asset Name",
    originalTitle: "Asset Name",
    field: "assetName",
    sortColumn: "Asset.Name",
    editable: true,
    visible: true,
    filter: {
      field: "Name",
      title: "Asset name",
      type: "string",
      value: { operator: 7, valuePrimary: "", valueSecondary: null }
    }
  },
  {
    title: "Variable",
    originalTitle: "Variable",
    field: "class",
    sortColumn: "IncidentClassLabel",
    editable: true,
    visible: true,
    filter: {
      field: "IncidentClassId",
      title: "Variable",
      type: "lookup-multi",
      lookupName: "INCIDENTTYPE",
    }
  },
  {
    title: "Horizontal distance [m]",
    originalTitle: "Horizontal distance [m]",
    field: "distance",
    sortColumn: "AssetDistance",
    editable: true,
    visible: true,
    filter: {
      field: "assetDistance",
      title: "Horizontal distance [m]",
      type: "number",
      value: { operator: 6, valuePrimary: null, valueSecondary: null }
    }
  },
  {
    title: "Absolute distance [m]",
    originalTitle: "Absolute distance [m]",
    field: "distance3D",
    sortColumn: "AssetDistance3D",
    editable: true,
    visible: true,
    filter: {
      field: "assetDistance3d",
      title: "Absolute distance [m]",
      type: "number",
      value: { operator: 6, valuePrimary: null, valueSecondary: null }
    }
  },
  {
    title: "Ndvi",
    originalTitle: "Ndvi",
    field: "ndvi",
    sortColumn: "NDVI",
    editable: true,
    visible: true,
    filter: {
      field: "NDVI",
      title: "Ndvi",
      type: "number",
      value: { operator: 11, valuePrimary: null, valueSecondary: null }
    }
  },
  {
    title: "Height estimate [m]",
    originalTitle: "Height estimate [m]",
    field: "height",
    sortColumn: "Height",
    editable: true,
    visible: true,
    filter: {
      field: "height",
      title: "Height estimate [m]",
      type: "number",
      value: { operator: 4, valuePrimary: null, valueSecondary: null }
    }
  },
  {
    title: "Area [m2]",
    originalTitle: "Area [m2]",
    field: "area",
    sortColumn: "Area",
    editable: true,
    visible: true,
    filter: {
      field: "area",
      title: "Area [m2]",
      type: "number",
      value: { operator: 6, valuePrimary: null, valueSecondary: null }
    }
  },
  {
    title: "Estimated production capacity [kW]",
    field: "estimatedSolarProductionCapacity",
    originalTitle: "Estimated production capacity",
    sortColumn: "EstimatedSolarProductionCapacity",
    editable: true,
    visible: false,
    filter: {
      field: "estimatedSolarProductionCapacity",
      title: "Estimated production capacity [kW]",
      type: "number"
    }
  },
  {
    title: "Date",
    originalTitle: "Date",
    field: "date",
    sortColumn: "Date",
    editable: true,
    visible: true,
    filter: {
      field: "date",
      title: "Date",
      type: "date"
    }
  },
  {
    title: "Asset type",
    originalTitle: "Asset type",
    field: "assetType",
    sortColumn: "Asset.AssetType",
    editable: true,
    visible: false,
    filter: {
      field: "assetType",
      title: "Asset type",
      type: "string",
    }
  },
  {
    title: "Total length of asset [m]",
    originalTitle: "Total length of asset [m]",
    field: "assetLength",
    sortColumn: "Asset.AssetLength",
    editable: true,
    visible: false,
    filter: {
      field: "assetLength",
      title: "Total length of asset [m]",
      type: "number",
    }
  },
  {
    title: "Asset Category",
    field: "assetCategory",
    originalTitle: "Asset Category",
    sortColumn: "Asset.AssetCategory",
    editable: true,
    visible: false,
    filter: {
      field: "AssetCategory",
      title: "Asset Category",
      type: "string",
      value: { operator: 7, valuePrimary: "", valueSecondary: null }
    }
  },
  {
    title: "Elevation",
    field: "elevation",
    originalTitle: "Elevation",
    sortColumn: "Asset.Elevation",
    editable: true,
    visible: false,
    filter: {
      title: "Elevation",
      field: "elevation",
      type: "number",
    }
  },
  {
    title: "Asset Location",
    field: "assetLocation",
    originalTitle: "Asset Location",
    sortColumn: "Asset.AssetLocation",
    editable: true,
    visible: false,
    filter: {
      field: "assetLocation",
      title: "Asset Location",
      type: "string",
    }
  },
  {
    title: "Location subgroup",
    field: "assetSublocation",
    originalTitle: "Location subgroup",
    sortColumn: "Asset.AssetSublocation",
    editable: true,
    visible: false,
    filter: {
      field: "assetSublocation",
      title: "Location subgroup",
      type: "string",
    }
  },
  {
    title: "Start Point",
    field: "startName",
    originalTitle: "Start Point",
    sortColumn: "Asset.StartName",
    editable: true,
    visible: false,
    filter: {
      title: "Start Point",
      field: "startName",
      type: "string",
    }
  },
  {
    title: "End Point",
    field: "endName",
    originalTitle: "End Point",
    sortColumn: "Asset.EndName",
    editable: true,
    visible: false,
    filter: {
      title: "End Point",
      field: "endName",
      type: "string",
    }
  }
];
