import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "@envs/environment";
import { JsonCollectionResponse, JsonItemResponse, JsonResponse } from "@/_models/response";
import { DialogService } from '@/_services/dialog.service';
import { AnalysisImportComponent } from '../analysis-import/analysis-import.component';

@Component({
  standalone: false,
  selector: 'assets-import',
  templateUrl: './assets-import.component.html',
  styleUrls: ['./assets-import.component.scss']
})
export class AssetsImportComponent {
  public loading: boolean = false;
  public item: any = {};
  public content: IDictionary<string>[] = [];
  public contentColumns: string[] = [];
  public guid: string = "";
  public fileUploaded: boolean = false;
  public properties: Properties;
  public nameColumn: string = "";
  public enableImport: boolean = false;

  http: HttpClient;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AnalysisImportComponent>,
    private _http: HttpClient,
    public dialog: MatDialog,
    public dialogService: DialogService
  ) {
    this.http = _http;
    this.properties = new Properties();
  }

  onCancelled(index: number) {
    this.guid = "";
  }

  onError(index: number, message: string) {
    this.dialogService.displayError(message);
    this.loading = false;
    this.guid = "";
  }

  onRemoved(index: number, guid: string) {
    this.guid = "";
  }

  onFileProgress(index: number, rangeStart: number, guid: string) {
  }

  onFileUploaded(index: number, guid: string) {
    this.loading = true;
    this.guid = guid;
    this.http.get<JsonCollectionResponse<IDictionary<any>>>(`${environment.apiUrl}/api/Resource/GetShapeFileContent?guid=${guid}`).subscribe(result => {
      if (result.ok) {
        this.content = [];
        this.contentColumns = [];
        var count = 0;
        if (result.items.length > 0) {
          for (let i = 0; i < result.items.length; i++) {
            var dict = result.items[i];
            var len = Object.keys(dict).length;
            if (this.contentColumns.length == 0) {
              Object.entries(dict).forEach(([key, value]: [string, any], index) => {
                this.contentColumns.push(value.key);
              });
            }

            var item: IDictionary<string> = {};
            Object.entries(dict).forEach(([key, value]: [string, any], index) => {
              item[value.key] = value.value;
            });
            this.content.push(item);
            if (count++ >= 15) {
              break;
            }
          }
        }

        this.loading = false;
        this.fileUploaded = true;
        this.enableImport = true;
      }
      else {
        this.dialogService.displayErrorMessages(result.messages);
        this.loading = false;
      }
    });
  }

  import() {
    this.loading = true;
    var request = {
      guid: this.guid,
      nameColumn: this.nameColumn,
      properties: this.properties
    }

    this.http.post<JsonItemResponse<number>>(`${environment.apiUrl}/api/Asset/Import`, request).subscribe(result => {
      if (result.ok) {
        this.dialogRef.close();
      }
      else {
        this.dialogService.displayErrorMessages(result.messages);
      }
      this.loading = false;
    });
  }
}

interface IDictionary<T> {
  [key: string]: T
}

export class Properties {
  polygonColor: string;
  polygonOutlineColor: string;
  polygonOutlineWidth: number;
  lineColor: string;
  lineWidth: number;
  pointColor: string;
  pointOutlineColor: string;
  pointWidth: number;
  textColor: string;
  textOutlineColor: string;
  textScale: number;
  textOverflow: boolean;

  constructor() {
    this.polygonColor = "#00000000";
    this.polygonOutlineColor = "#000000ff";
    this.polygonOutlineWidth = 2;
    this.lineColor = "#000000ff";
    this.lineWidth = 2;
    this.pointColor = "#00000000";
    this.pointOutlineColor = "#000000ff";
    this.pointWidth = 5;
    this.textColor = "#000000ff";
    this.textOutlineColor = "#ffffffff";
    this.textScale = 10;
    this.textOverflow = false;
  }
}
