import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string | null;
  messages: string[] | null;
  icon: string | null;
}

@Component({
  standalone: false,
  selector: 'info-dialog',
  styleUrls: ['info-dialog.component.scss'],
  templateUrl: 'info-dialog.component.html',
})
export class InfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
