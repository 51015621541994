import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { LookupService } from "@/_services/lookup.service";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from "ngx-bootstrap-multiselect";

@Component({
  standalone: false,
  selector: 'app-lookup-as-text',
  templateUrl: './lookup-as-text.component.html',
  styleUrls: ['./lookup-as-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LookupAsTextComponent),
      multi: true
    }
  ]
})

export class LookupAsTextComponent implements OnInit {
  @Input() name: string = "";
  @Input() showValues: any[] = [];
  lookupData: any;
  public chips: any[] = [];

  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.lookupService.getLookup(this.name, "").subscribe((results: any) => {
      if (results != null) {
        this.lookupData = results;
        let self = this;
        this.lookupData.forEach(function(option: any){
          if(self.showValues.indexOf(option.value) > -1) {
            self.chips.push(option)
          }
        })
      }
    });
  }
}
